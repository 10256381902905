<template>
  <div>
    <el-form ref="form" :model="form" label-width="100px">
      <el-form-item label="上传图片：">
        <el-upload
          action="/api/menu"
          :data="{ action: 'company.logo.upload' }"
          list-type="picture-card"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :file-list="imageList"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="介绍：">
        <el-input
          type="textarea"
          placeholder="请输入内容"
          v-model="form.message"
          style="width:500px"
        >
        </el-input>
      </el-form-item>
    </el-form>
    <el-button type="primary" plain @click="save" size="small">
      保存
    </el-button>
  </div>
</template>

<script>
import { apiPost } from "../../../modules/utils";
import APIs from "../../../modules/APIs";
import { mapActions } from "vuex";
import * as imageConversion from "image-conversion";

const actions = mapActions(["setAlert"]);

export default {
  data() {
    return {
      form: {},
      imageList: [],
      textarea: "",
    };
  },
  mounted() {
    this.getCompanyInfo();
  },
  methods: {
    ...actions,
    //获取公司介绍
    getCompanyInfo() {
      let _this = this;
      apiPost({
        url: APIs.PROPERTY,
        data: {
          action: "company.setting.get",
        },
        success(res) {
          if (res.code === "0") {
            console.log(res);

            _this.imageList = res.data.image_url.map((item, index) => {
              return {
                name: index,
                url: item,
              };
            });
            console.log(_this.imageList);
            _this.form = {
              ..._this.form,
              message: res.data.message,
            };
          } else {
            _this.setAlert({ msg: res.msg, type: false });
          }
        },
      });
    },
    handleRemove(file) {
      console.log(file);
      this.imageList = this.imageList.filter((c) => c.uid != file.uid);
    },
    handleSuccess(res) {
      if (res.code == 0) {
        const { data } = res;
        const image = [data].map((c) => ({
          url: "http://" + c.domain + "/" + c.path,
        }));
        console.log(image);
        this.imageList = [...this.imageList, ...image];
        console.log(this.imageList);
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeUpload(file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        this.$message.warning("只能上传照片");
        return false;
      }

      return new Promise((resolve) => {
        console.log(file);
        let check = file.size / 1024 < 300; // 判定图片大小是否小于500KB
        if (check) {
          resolve(file);
          console.log(file);
        } else {
          console.log(file);
          this.$confirm(
            "您上传的图片过大,是否进行压缩上传？(此操作会降低图片质量)",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              imageConversion.compressAccurately(file, 200).then((res) => {
                res = new File([res], file.name, {
                  type: res.type,
                  lastModified: Date.now(),
                });
                resolve(res);
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消上传",
              });
              this.imageList = this.imageList.filter((c) => c.uid !== file.uid);
            });
        }
      });
    },
    save() {
      const image_url = this.imageList.map((c) => c.url);
      console.log(this.chooseArr);
      let _this = this;
      apiPost({
        url: APIs.PROPERTY,
        data: {
          action: "company.setting.set",
          image_url: image_url,
          message: this.form.message,
        },
        success(res) {
          if (res.code === "0") {
            _this.setAlert({ msg: res.msg, type: true });
            _this.getCompanyInfo();
          } else {
            _this.setAlert({ msg: res.msg, type: false });
          }
        },
      });
    },
  },
};
</script>

<style></style>

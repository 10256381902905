<template>
  <div class="wrapper" ref="page">
    <ctheader
      :titles="pageInfo.titles"
      :back-btn="pageInfo.backBtn"
      :back-path="pageInfo.backPath"
    />

    <div class="content">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="招商平台设置" name="first">
          <Setting ref="setting" />
        </el-tab-pane>
        <el-tab-pane label="公司介绍" name="second">
          <Introduce ref="introduce" />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import ctheader from "../../components/ctheader";
import { apiPost } from "../../modules/utils";
import APIs from "../../modules/APIs";
import Introduce from "./components/introduce";
import Setting from "./components/setting";

import { mapActions } from "vuex";

const actions = mapActions(["setAlert"]);

export default {
  components: { ctheader, Introduce, Setting },

  data() {
    return {
      pageInfo: {
        titles: [],
        backBtn: false,
      },
      form: {},
      imageList: [],
      show: false,
      marketForm: {
        start: 0,
        length: 50,
      },
      tableData: [],
      total: 0,
      new_marketList: [],
      chooseArr: [],
      skip_url: "",
      activeName: "first",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pageInfo.titles = to.meta.title;
    });
  },
  methods: {
    ...actions,
    handleSuccess(res) {
      if (res.code == 0) {
        const { data } = res;
        const image = [data].map((c) => ({
          url: "http://" + c.domain + "/" + c.path,
        }));
        console.log(image);
        this.imageList = [...this.imageList, ...image];
        console.log(this.imageList);
      } else {
        this.$message.error(res.msg);
      }
    },
    handleRemove() {},
    addMarket() {
      this.show = true;
      this.getMarketList();
    },
    handleFilter() {},
    handleSelectionChange(val) {
      console.log(val);
      this.new_marketList = val;
    },
    close() {},
    saveChooseGoods() {
      this.chooseArr = this.new_marketList;
      this.show = false;
      console.log(this.chooseArr);
    },
    delGoods(val) {
      this.chooseArr.some((item, i) => {
        if (item.id == val.id) {
          this.chooseArr.splice(i, 1); //在数组的some方法中，如果return true，就会立即终止这个数组的后续循环
          return true;
        }
      });
    },
    getMarketList(index = 0) {
      let _this = this;
      this.marketForm.start = index;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "market.list",
          start: this.marketForm.start,
          length: this.marketForm.length,
        },
        success(res) {
          if (res.code === "0") {
            _this.tableData = res.data;
            _this.total = res.total.total;
          }
        },
      });
    },
    save() {
      const image_url = this.imageList.map((c) => c.url);
      console.log(this.chooseArr);
      let _this = this;
      apiPost({
        url: APIs.PROPERTY,
        data: {
          action: "setting.set",
          image_url: image_url,
          skip_url: _this.skip_url,
          market_list: _this.chooseArr.map((item) => {
            return {
              id: item.id,
              market_code: item.market_code,
              market_name: item.market_name,
              addr_detail: item.addr_detail,
              responsible_person: item.responsible_person,
              responsible_mobile: item.responsible_mobile,
              sort: item.sort,
              remark: item.remark,
            };
          }),
        },
        success(res) {
          if (res.code === "0") {
            _this.setAlert({ msg: res.msg, type: true });
          } else {
            _this.setAlert({ msg: res.msg, type: false });
          }
        },
      });
    },
    handleClick() {
      if (this.activeName == "first") {
        this.$refs.setting.getInfo();
      } else {
        this.$refs.introduce.getCompanyInfo();
      }
    },
  },
};
</script>

<style lang="less">
.el-button--primary.is-plain {
  background-color: #257873;
  color: #ffffff;
  border: 1px solid #257873;
}
.el-button--primary.is-plain:hover {
  background-color: #257873;
  color: #ffffff;
  border: 1px solid #257873;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #257873;
  border-color: #257873;
}
.el-button--text {
  color: #257873;
}
.el-checkbox__inner:hover {
  border-color: #257873;
}
.el-button--text:hover {
  color: #257873;
}
.el-tabs__item.is-active {
  color: #257873;
}
.el-tabs__active-bar {
  background: #257873;
}
.el-tabs__item:hover {
  color: #257873;
}
</style>

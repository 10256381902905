<template>
  <div>
    <el-form ref="form" :model="form" label-width="120px">
      <el-form-item label="上传图片：">
        <el-upload
          action="/api/menu"
          :data="{ action: 'company.logo.upload' }"
          list-type="picture-card"
          :on-success="handleSuccess"
          :on-remove="handleRemove"
          :file-list="imageList"
          :before-upload="beforeUpload"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="跳转链接：">
        <el-input v-model="skip_url" size="small" style="width:300px">
        </el-input>
      </el-form-item>
      <el-form-item label="首页分享图片：">
        <el-upload
          :class="{ disUoloadSty: shareImageList.length != 0 }"
          action="/api/menu"
          :data="{ action: 'company.logo.upload' }"
          list-type="picture-card"
          :on-success="handleSuccess1"
          :on-remove="handleRemove1"
          :file-list="shareImageList"
          :before-upload="beforeUpload"
          :limit="1"
        >
          <i class="el-icon-plus"></i>
        </el-upload>
      </el-form-item>
      <el-form-item label="首页分享描述：">
        <el-input v-model="remark" size="small" style="width:300px"></el-input>
      </el-form-item>
      <el-divider></el-divider>
      <el-form-item label="添加入驻招商平台市场：" label-width="180px">
        <el-button size="mini" @click="addMarket" type="text">
          添加市场
        </el-button>
      </el-form-item>
      <el-table
        :data="chooseArr"
        tooltip-effect="dark"
        style="width: 100%;"
        v-if="chooseArr.length > 0"
      >
        <el-table-column label="市场编号" prop="market_code"> </el-table-column>
        <el-table-column property="market_name" label="市场名称">
        </el-table-column>
        <el-table-column property="addr_detail" label="地址"> </el-table-column>
        <el-table-column property="responsible_person" label="负责人">
        </el-table-column>
        <el-table-column property="responsible_mobile" label="联系方式">
        </el-table-column>
        <el-table-column label="排序">
          <template scope="scope">
            <el-input-number
              v-model="scope.row.sort"
              size="small"
              :controls="false"
            >
            </el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template scope="scope">
            <el-input v-model="scope.row.remark" size="small"> </el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="130">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="delGoods(scope.row)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <el-dialog
      title="添加市场"
      :visible.sync="show"
      :before-close="closeDialog"
    >
      <el-form :inline="true" :model="marketForm">
        <el-form-item>
          <el-input
            v-model="marketForm.market_name"
            placeholder="市场名称"
            suffix-icon="el-icon-search"
            clearable
            size="small"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="marketForm.contact"
            placeholder="负责人名称"
            suffix-icon="el-icon-search"
            clearable
            size="small"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" plain @click="handleFilter" size="small">
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" plain @click="saveChooseGoods" size="small">
            保存
          </el-button>
        </el-form-item>
      </el-form>
      <div class="dialog-table" style="height:500px;overflow:auto">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%;"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"> </el-table-column>

          <el-table-column label="市场编号" prop="market_code">
          </el-table-column>
          <el-table-column property="market_name" label="市场名称">
          </el-table-column>
          <el-table-column property="addr_detail" label="地址">
          </el-table-column>
          <el-table-column property="responsible_person" label="负责人">
          </el-table-column>
          <el-table-column property="responsible_mobile" label="联系方式">
          </el-table-column>
        </el-table>
        <pagination
          :total="total"
          :start.sync="marketForm.start"
          :length.sync="marketForm.length"
          :pageCallback="getMarketList"
        >
        </pagination>
      </div>
    </el-dialog>
    <el-button type="primary" plain @click="save" size="small">
      保存
    </el-button>
  </div>
</template>

<script>
import pagination from "../../../components/pagination";
import { apiPost } from "../../../modules/utils";
import APIs from "../../../modules/APIs";
import { mapActions } from "vuex";
import * as imageConversion from "image-conversion";

const actions = mapActions(["setAlert"]);

export default {
  components: { pagination },

  data() {
    return {
      pageInfo: {
        titles: [],
        backBtn: false,
      },
      form: {},
      imageList: [],
      show: false,
      marketForm: {
        start: 0,
        length: 50,
      },
      tableData: [],
      total: 0,
      new_marketList: [],
      chooseArr: [],
      skip_url: "",
      activeName: "first",
      shareImageList: [],
      remark: "",
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.pageInfo.titles = to.meta.title;
    });
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    ...actions,
    getInfo() {
      let _this = this;
      apiPost({
        url: APIs.PROPERTY,
        data: {
          action: "setting.get",
        },
        success(res) {
          if (res.code === "0") {
            console.log(res);
            _this.chooseArr = res.data.market_list;
            _this.skip_url = res.data.skip_url;
            _this.remark = res.data.remark;
            _this.shareImageList = [{ name: 1, url: res.data.image }];

            _this.imageList = res.data.image_url.map((item, index) => {
              return {
                name: index,
                url: item,
              };
            });
            console.log(_this.imageList);
          } else {
            _this.setAlert({ msg: res.msg, type: false });
          }
        },
      });
    },
    handleSuccess(res) {
      if (res.code == 0) {
        const { data } = res;
        const image = [data].map((c) => ({
          url: "http://" + c.domain + "/" + c.path,
        }));
        console.log(image);
        this.imageList = [...this.imageList, ...image];
        console.log(this.imageList);
      } else {
        this.$message.error(res.msg);
      }
    },
    handleSuccess1(res) {
      if (res.code == 0) {
        const { data } = res;
        const image = [data].map((c, index) => ({
          url: "http://" + c.domain + "/" + c.path,
          name: index,
        }));
        console.log(image);
        this.shareImageList = image;
      } else {
        this.$message.error(res.msg);
      }
    },
    beforeUpload(file) {
      if (
        file.type !== "image/png" &&
        file.type !== "image/jpg" &&
        file.type !== "image/jpeg"
      ) {
        this.$message.warning("只能上传照片");
        return false;
      }

      return new Promise((resolve) => {
        console.log(file);
        let check = file.size / 1024 < 300; // 判定图片大小是否小于500KB
        if (check) {
          resolve(file);
          console.log(file);
        } else {
          console.log(file);
          this.$confirm(
            "您上传的图片过大,是否进行压缩上传？(此操作会降低图片质量)",
            "提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          )
            .then(() => {
              imageConversion.compressAccurately(file, 200).then((res) => {
                res = new File([res], file.name, {
                  type: res.type,
                  lastModified: Date.now(),
                });
                resolve(res);
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消上传",
              });
              this.imageList = this.imageList.filter((c) => c.uid !== file.uid);
            });
        }
      });
    },
    handleRemove(file) {
      this.imageList = this.imageList.filter((c) => c.uid != file.uid);
    },
    handleRemove1() {
      this.shareImageList = [];
    },
    addMarket() {
      this.show = true;
      this.getMarketList();
    },
    handleFilter() {
      this.marketForm.start = 0;
      this.getMarketList();
    },
    handleSelectionChange(val) {
      console.log(val);
      this.new_marketList = val;
    },
    saveChooseGoods() {
      // this.chooseArr = this.new_marketList;
      //目前选中的所有数据 this.new_marketList
      //在选中的数据里 过滤掉之前的 arr1
      const chooseArrIds = this.chooseArr.map((item) => item.id);
      const arr1 = this.new_marketList.filter(
        (c) => !chooseArrIds.includes(c.id)
      );
      console.log(arr1);
      this.show = false;
      const oldChooseArr = this.chooseArr;
      this.chooseArr = arr1.concat(oldChooseArr);
      console.log(this.chooseArr);
      this.marketForm = {};
    },
    delGoods(val) {
      this.chooseArr.some((item, i) => {
        if (item.id == val.id) {
          this.chooseArr.splice(i, 1); //在数组的some方法中，如果return true，就会立即终止这个数组的后续循环
          return true;
        }
      });
    },
    getMarketList(index = 0) {
      let _this = this;
      _this.marketForm.start = index;
      apiPost({
        url: APIs.COMPANY,
        data: {
          action: "market.list",
          start: _this.marketForm.start,
          length: _this.marketForm.length,
          market_name: _this.marketForm.market_name,
          contact: _this.marketForm.contact,
        },
        success(res) {
          if (res.code === "0") {
            _this.tableData = res.data;
            _this.total = res.total.total;
            const ids = _this.tableData;
            console.log(ids);
            const choose_ids = _this.chooseArr.map((item) => item.id);
            const rows = ids.filter((c) => choose_ids.includes(c.id));
            console.log(choose_ids);
            console.log(rows);
            _this.$nextTick(() => {
              rows.forEach((row) => {
                _this.$refs.multipleTable.toggleRowSelection(row, true);
              });
            });
          }
        },
      });
    },
    closeDialog() {
      this.marketForm = {};
      this.show = false;
    },
    save() {
      const image_url = this.imageList.map((c) => c.url);
      console.log(image_url);

      let _this = this;
      apiPost({
        url: APIs.PROPERTY,
        data: {
          action: "setting.set",
          image_url: image_url,
          skip_url: _this.skip_url,
          image:
            _this.shareImageList.length > 0 ? _this.shareImageList[0].url : "",
          remark: _this.remark,
          market_list: _this.chooseArr.map((item) => {
            return {
              market_orgz_id: item.id,
              market_code: item.market_code,
              market_name: item.market_name,
              addr_detail: item.addr_detail,
              responsible_person: item.responsible_person,
              responsible_mobile: item.responsible_mobile,
              sort: item.sort,
              remark: item.remark,
            };
          }),
        },
        success(res) {
          if (res.code === "0") {
            _this.setAlert({ msg: res.msg, type: true });
            _this.getInfo();
          } else {
            _this.setAlert({ msg: res.msg, type: false });
          }
        },
      });
    },
  },
};
</script>

<style lang="less">
.el-button--primary.is-plain {
  background-color: #257873;
  color: #ffffff;
  border: 1px solid #257873;
}
.el-button--primary.is-plain:hover {
  background-color: #257873;
  color: #ffffff;
  border: 1px solid #257873;
}
.el-button--primary.is-plain:focus {
  background-color: #257873;
  color: #ffffff;
  border: 1px solid #257873;
}
.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #257873;
  border-color: #257873;
}
.el-button--text {
  color: #257873;
}
.el-checkbox__inner:hover {
  border-color: #257873;
}
.el-button--text:hover {
  color: #257873;
}
.el-tabs__item.is-active {
  color: #257873;
}
.el-tabs__active-bar {
  background: #257873;
}
.el-tabs__item:hover {
  color: #257873;
}
.el-dialog__headerbtn .el-dialog__close:hover {
  color: #257873;
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
